var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"overlay-panel overlay-left"},[_c('h1',{class:{
      'px-2 my-4 sm-title font-weight-bold': _vm.$vuetify.breakpoint.smAndDown,
      'px-8 my-4 md-title font-weight-bold': _vm.$vuetify.breakpoint.mdAndUp,
    }},[_vm._v(" Welcome Back! ")]),_c('p',{class:{
      'pa-4  sm-description': _vm.$vuetify.breakpoint.smAndDown,
      'pa-8 md-description': _vm.$vuetify.breakpoint.mdAndUp,
    }},[_vm._v(" If you already have an account please Sign In from here ! ")]),_c('v-btn',{class:{
      'pa-4 my-10 font-weight-bold': _vm.$vuetify.breakpoint.smAndDown,
      'pa-6 my-10 font-weight-bold': _vm.$vuetify.breakpoint.mdAndUp,
    },attrs:{"color":"info","dark":"","rounded":"","elevation":"0"},on:{"click":function($event){_vm.isSignInPanelActive = false}}},[_vm._v(" Sign In ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }