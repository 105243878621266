var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"padding-top":"5rem"}},[_c('h1',{class:{
      ' font-weight-bold ': _vm.$vuetify.breakpoint.smAndDown,
      ' font-weight-bold ': _vm.$vuetify.breakpoint.mdAndUp,
    }},[_vm._v(" Dental Anxiety therapy using Self-Empowerment Cinematography ")]),_vm._m(0),_vm._m(1),_vm._m(2),_c('div',[_c('router-link',{staticClass:"erp-header-link",attrs:{"to":_vm.fullPath}},[_c('v-btn',{class:{
      'pa-4 my-10 font-weight-bold': _vm.$vuetify.breakpoint.smAndDown,
      'pa-6 my-10 font-weight-bold': _vm.$vuetify.breakpoint.mdAndUp,
    },attrs:{"color":"info","dark":"","rounded":"","elevation":"0"}},[_vm._v(" Login ")])],1)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"padding":"20px"}},[_c('h2',[_vm._v("A collaborative research program in association with the University of Queensland Dental School.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"padding":"20px"}},[_c('h4',[_vm._v("Dr Ian Davies, BDSC (Hons) QLD – Dentist at Restore Dental and Prosthodontics")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h4',[_vm._v("A/Professor Ratilal Lalloo, BChD, BSc Med Hons (Epidemiol), MChD (Community Dentistry), PhD (Dental Public Health – London)")]),_c('h4',[_vm._v(", Discipline Lead: Dental Public Health, Director (Research Training).")])])
}]

export { render, staticRenderFns }