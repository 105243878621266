var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{class:_vm.isSignInPanelActive ? 'container right-panel-active' : 'container',attrs:{"id":"container"}},[_c('div',{staticStyle:{"padding-top":"5rem"}},[_c('h1',{class:{
        ' font-weight-bold ': _vm.$vuetify.breakpoint.smAndDown,
        ' font-weight-bold ': _vm.$vuetify.breakpoint.mdAndUp,
      }},[_vm._v(" Dental Anxiety therapy using Self-Empowerment Cinematography ")]),_c('div',{staticStyle:{"padding":"20px"}},[_c('h2',[_vm._v("A collaborative research program in association with the University of Queensland Dental School.")])]),_c('div',{staticStyle:{"padding":"20px"}},[_c('h4',[_vm._v("Dr Ian Davies, BDSC (Hons) QLD – Dentist at Restore Dental and Prosthodontics")])]),_c('div',[_c('h4',[_vm._v("A/Professor Ratilal Lalloo, BChD, BSc Med Hons (Epidemiol), MChD (Community Dentistry), PhD (Dental Public Health – London)")]),_c('h4',[_vm._v(", Discipline Lead: Dental Public Health, Director (Research Training).")])]),_c('div',[_c('h1',{class:{
        ' font-weight-bold ': _vm.$vuetify.breakpoint.smAndDown,
        ' font-weight-bold ': _vm.$vuetify.breakpoint.mdAndUp,
      }},[_vm._v(" We invite you to participate in an exciting and innovative new therapy to help people affected by Dental Fear and Anxiety. ")])]),_c('div',[_c('router-link',{staticClass:"erp-header-link",attrs:{"to":_vm.fullPath}},[_c('v-btn',{class:{
        'pa-4 my-10 font-weight-bold': _vm.$vuetify.breakpoint.smAndDown,
        'pa-6 my-10 font-weight-bold': _vm.$vuetify.breakpoint.mdAndUp,
      },attrs:{"color":"info","dark":"","rounded":"","elevation":"0"}},[_vm._v(" Register Here ")])],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }