var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{class:{
  'container ': _vm.$vuetify.breakpoint.smAndDown,
  'container  ': _vm.$vuetify.breakpoint.mdAndUp,
}},[_c('h1',{class:{
    'sm-title font-weight-bold ': _vm.$vuetify.breakpoint.smAndDown,
    'md-title font-weight-bold ': _vm.$vuetify.breakpoint.mdAndUp,
  }},[_vm._v(" SEC Film ")]),_c('div',{staticStyle:{"text-align":"start","padding":"10px","border-bottom":"1px solid white"}},[_c('p',[_vm._v(".The SEC film takes 4 minutes to watch each day on a computer with headphones.")]),_c('p',[_vm._v(".This can be performed at home or any other location you feel comfortable. You will use the SEC once a day for 90 days.")]),_c('p',[_vm._v(".Each time you view the SEC film you will mark this down on the progress chart. You will be given 4 months to complete this task.")]),_c('p',[_vm._v(".At the end of the 90 SEC film viewings you will fill out the IDAF-4C+ again to measure the changes in your level of dental anxiety..")])]),_c('div',{staticStyle:{"padding":"30px"},attrs:{"id":"vimeo-player"}},[_c('iframe',{attrs:{"width":"100%","height":"600px","src":"https://streamable.com/e/lv64h7?loop=0?Id=APKAIEYUVEN4EVB2OKEQ","frameborder":"0","allowfullscreen":""}})]),_c('p',[_vm._v("No. of views: "),_c('span',{staticStyle:{"color":"red"}},[_vm._v(" "+_vm._s(_vm.getWatchNumber)+" ")])]),_c('div',{staticClass:"d-flex justify-center align-center pa-10",staticStyle:{"width":"100%"}},[(_vm.wtch)?_c('v-checkbox',{staticClass:"mx-2",attrs:{"label":"I watched the video "},model:{value:(_vm.watched),callback:function ($$v) {_vm.watched=$$v},expression:"watched"}}):_vm._e(),_c('v-btn',{staticClass:"mx-2",attrs:{"disabled":!_vm.watched,"color":"primary"},on:{"click":function($event){return _vm.updatepaused()}}},[_vm._v("submit")]),(_vm.finshWhatched)?_c('div',[_c('router-link',{staticClass:"erp-header-link",attrs:{"to":_vm.goLastTest}},[_c('v-btn',{staticClass:"mx-2",attrs:{"color":"primary"}},[_vm._v("Finish watching")])],1)],1):_vm._e()],1),_c('div',{staticClass:"text-center"},[_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-text',{staticClass:"pa-10"},[_vm._v(" "+_vm._s(_vm.message)+" ")]),_c('v-divider')],1)],1)],1),_vm._v(" "),_c('div')])
}
var staticRenderFns = []

export { render, staticRenderFns }