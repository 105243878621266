var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{class:{
			'container  ': _vm.$vuetify.breakpoint.smAndDown,
			'container ': _vm.$vuetify.breakpoint.mdAndUp,
		}},[_c('div',[_c('v-row',[_c('v-col')],1),_c('v-row',[_c('v-col')],1),_c('v-row',[_c('v-col')],1),_c('v-row',[_c('v-col')],1),_c('v-row',[_c('v-col')],1),_c('v-row',[_c('v-col')],1),_c('v-row',[_c('v-col')],1),_c('v-row',[_c('v-col')],1),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('h1',{staticClass:"text-h4 font-weight-thin mb-4"},[_vm._v(" Thank You! ")]),_c('h4',{staticClass:"subheading"},[_vm._v(" Thank you for participating in this experience. We appreciate your time and contribution. ")])])],1),_c('v-btn',{class:{
        'pa-4 my-10 font-weight-bold': _vm.$vuetify.breakpoint.smAndDown,
        'pa-6 my-10 font-weight-bold': _vm.$vuetify.breakpoint.mdAndUp,
      },attrs:{"color":"info","dark":"","rounded":"","elevation":"0"},on:{"click":_vm.fullPath}},[_vm._v(" Login ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }