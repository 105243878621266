<template>
  <v-container :class="{
    'container ': $vuetify.breakpoint.smAndDown,
    'container  ': $vuetify.breakpoint.mdAndUp,
  }">
    <h1 :class="{
      'sm-title font-weight-bold ': $vuetify.breakpoint.smAndDown,
      'md-title font-weight-bold ': $vuetify.breakpoint.mdAndUp,
    }">
      SEC Film
    </h1>
    <div style="text-align: start; padding: 10px; border-bottom: 1px solid white;">
      <p>.The SEC film takes 4 minutes to watch each day on a computer with headphones.</p>
      <p>.This can be performed at home or any other location you feel comfortable. You will use the SEC once a day for
        90 days.</p>
      <p>.Each time you view the SEC film you will mark this down on the progress chart. You will be given 4 months to
        complete this task.</p>
      <p>.At the end of the 90 SEC film viewings you will fill out the IDAF-4C+ again to measure the changes in your
        level of dental anxiety..</p>
    </div>
    <!-- {{ formatedCountdown || 'countdown over' }} -->
    <div style="padding: 30px;"  id="vimeo-player">
        <iframe
        width="100%"
        height="600px"
        src="https://streamable.com/e/lv64h7?loop=0?Id=APKAIEYUVEN4EVB2OKEQ"
        frameborder="0"
        allowfullscreen
      ></iframe>
      </div>
      <p>No. of views: <span style="color: red;"> {{ getWatchNumber }} </span> </p>
      <div class="d-flex justify-center align-center pa-10" style="width:100%;">
        <v-checkbox label="I watched the video " class="mx-2" v-model="watched" v-if="wtch" ></v-checkbox>
        <v-btn @click="updatepaused()" class="mx-2" :disabled="!watched"  color="primary">submit</v-btn>
        <div v-if="finshWhatched">
        <router-link class="erp-header-link" :to="goLastTest">
        <v-btn  class="mx-2"  color="primary">Finish watching</v-btn>
        </router-link>
      </div>
      </div>
      <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-card>
        <v-card-text class="pa-10">
          {{ message }}
        </v-card-text>

        <v-divider></v-divider>
      </v-card>
    </v-dialog>
  </div>      <div>
    </div>
  </v-container>
</template>
  
  
  
<script>
import * as moment from 'moment'
import 'moment-duration-format'
export default {
  name: 'video-page',
  data(){
    return {
      watched: false,
      wtch:false,
      dialog: false,
      countdown: 200,
    }
  },

  computed: {
    formatedCountdown() {
      return moment.duration(this.countdown, 'seconds').format('m:ss')
    },
   
    getWatchNumber(){
   
       return this.$store.getters['authPageModule/getWatchNumber']
      
    },
    goLastTest(){
      return '/lastTestingUser'
    },
    
    finshWhatched(){
      return this.$store.getters['authPageModule/getFinshWotchVedios']
    },
    message(){
      return this.$store.getters['authPageModule/getMessageWatched']
    }
  },
  mounted() {
    const stopCountdown = setInterval(() => {
      if (this.countdown == 1) {
        this.wtch = true
        
      }
      this.countdown -= 1
      if (!this.countdown) clearInterval(stopCountdown)
    }, 1000)
  },
  methods: {
    
    updatepaused() {
      
      if (this.watched) {
        this.$store.dispatch("authPageModule/sendUserwhatchVedio").then(()=>{
          this.dialog = true
         
        })  
      }
    },
    logout() {
      localStorage.removeItem('token');
      this.$router.push("/");
      window.location.reload()
    },
    startWatch(){
      let vid = document.getElementById("video1toWatch");
      console.log('vid',vid.duration,vid.currentTime,vid);
    }
  },
  created() {
      if (localStorage.getItem('token')) {
        console.log('that is true rejester');
        this.$store.dispatch("authPageModule/NumperWhatheVidoe")  
      } else {
        this.$router.push('/')
      }
  }
};
</script>
  
  
<style scoped>
.overlay-right {
  right: 0;
  transform: translateX(0);
}
</style>