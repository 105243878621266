var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-overlay",attrs:{"id":"app"}},[_c('v-app',[_c('div',{class:{
        'sm-main-style': _vm.$vuetify.breakpoint.smAndDown,
        'md-main-style': _vm.$vuetify.breakpoint.mdAndUp,
      }},[_c('div',[_c('v-row',{staticClass:"my-4"},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[(_vm.testPath)?_c('v-btn',{class:{
                'p-0  m-0': _vm.$vuetify.breakpoint.smAndDown,
                'mx-1': _vm.$vuetify.breakpoint.mdAndUp,
              },attrs:{"tile":"","block":"","elevation":"0","color":"info"},on:{"click":_vm.logout}},[_vm._v("Logout")]):_vm._e()],1)],1),_c('v-row',{staticClass:"my-4"},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[(_vm.backPathe)?_c('v-btn',{class:{
                'p-0  m-0': _vm.$vuetify.breakpoint.smAndDown,
                'mx-1': _vm.$vuetify.breakpoint.mdAndUp,
              },attrs:{"tile":"","block":"","elevation":"0","color":"info"},on:{"click":_vm.Back}},[_vm._v("Back")]):_vm._e()],1)],1)],1),_c('router-view'),_c('footer',{class:{
                'footer-sm': _vm.$vuetify.breakpoint.smAndDown,
                'footer-md': _vm.$vuetify.breakpoint.mdAndUp,
              }},[_c('a',{attrs:{"href":"#","target":"_blank"}})])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }